import React from "react";
// import USAToday from './../images/contributing-for/usa-today.inline.svg';
// import BusinessInsider from './../images/contributing-for/business-insider.inline.svg';
// import NextAdvisor from './../images/contributing-for/next-advisor.inline.svg';
// import FOX from './../images/contributing-for/fox.inline.svg';
// import NBC from './../images/contributing-for/nbc.inline.svg';
// import FastCompany from './../images/contributing-for/fast-company.inline.svg';
// import Time from './../images/contributing-for/time.inline.svg';
// import Fortune from './../images/contributing-for/fortune.inline.svg';
// import SocialMediaExaminer from './../images/contributing-for/social-media-examiner.inline.svg';
// import Out from './../images/contributing-for/out.inline.svg';
// import CBS from './../images/contributing-for/cbs.inline.svg';
// import Enterpreneur from './../images/contributing-for/enterpreneur.inline.svg';
import { GatsbyImage } from "gatsby-plugin-image";

const ContributingFor = ({ contributionSection }) => {
  return (
    <div className={"my-10 container"}>
      <h4
        className={
          "text-center font-bold font-ssp text-2xl bg-[url(./../images/contributing-for/heading-border-bottom.svg)] bg-no-repeat bg-bottom pb-5"
        }
      >
        {contributionSection?.title}
      </h4>
      <div
        className={"flex flex-wrap items-center justify-center gap-8 mt-5"}
        data-aos="fade-in"
        data-aos-offset="150"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in"
      >
        {contributionSection?.contributions?.map((contribution, i) => {
          if (!contribution?.asset?.gatsbyImageData) return <></>;
          return (
            <GatsbyImage
              alt={contribution.alt ?? "Contributor"}
              image={contribution.asset.gatsbyImageData}
              className={
                "w-36 hover:scale-110 transition-all duration-300 cursor-pointer"
              }
              // objectFit={'contain'}
              key={i}
            />
          );
        })}

        {/*<USAToday className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<BusinessInsider className={'h-10 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<NextAdvisor className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<FOX className={'h-9 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<NBC className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<FastCompany className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<Time className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<Fortune className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<NBC className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<SocialMediaExaminer className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<Out className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<CBS className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
        {/*<Enterpreneur className={'h-12 hover:scale-110 transition-all duration-300 cursor-pointer'}/>*/}
      </div>
    </div>
  );
};

export default ContributingFor;
